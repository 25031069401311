import Modal from 'react-modal';

import './offline-modal.scss';

const customStyles = {
  content: {
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(70, 23, 143,0.9)',
    color: '#fff',
    minWidth: '600px',
    minHeight: '600px',
    fontFamily: 'Montserrat, sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

Modal.setAppElement('#root');

export const OfflineModal = ({ display }: { display: boolean }) => {
  return (
    <Modal isOpen={display} style={customStyles} contentLabel="Network lost">
      {/*<div className="kahoot-theme">*/}
      {/*  <div className="bg-square"></div>*/}
      {/*  <div className="bg-circle"></div>*/}
      {/*</div>*/}
      <p>
        Reconnecting...
        <br /> Do not refresh your browser
      </p>
    </Modal>
  );
};
